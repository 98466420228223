<template>
  <div
    class="main"
    :style="{ display: fileNamePosition == 'bottom' ? 'column' : 'flex' }"
    @click="preView"
  >
    <div class="img">
      <i
        v-if="showDelet"
        @click.stop="deletFile"
        class="el-icon-circle-close"
      ></i>
      <img
        v-if="['png', 'jpg', 'jpeg'].includes(getIcon(suffix))"
        class="pic"
        :src="fileUrl"
      />
      <img
        v-else
        class="pic"
        :src="require(`./static/${getIcon(suffix)}.png`)"
      />
    </div>
    <!-- <div
      v-if="fileName"
      class="file-name"
      :style="{ 'margin-left': fileNamePosition == 'bottom' ? '0' : '12px' }"
    >
      {{ fileName }}
    </div> -->
  </div>
</template>

<script>
export default {
  name: "FileIcon2",
  props: {
    suffix: {
      type: String,
      default: "doc",
    },
    fileUrl: {
      type: String,
    },
    fileName: {
      type: String,
      default: "未知",
    },
    fileNamePosition: {
      type: String,
      default: "bottom",
    },
    showDelet: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      fileIcons: ["doc", "docx", "jpg", "pdf", "png", "xlsx", "xls"],
    };
  },
  methods: {
    deletFile() {
      this.$emit("deletFile");
    },
    preView() {
      this.$emit("preView");
    },
    getIcon(type) {
      if (this.fileIcons.indexOf(type) != -1) {
        return type;
      } else {
        return "unknown";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  display: column;
  align-items: center;
  display: inline-block;
  width: 100%;
  height: 100%;
  .img {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
    .el-icon-circle-close {
      display: none;
      position: absolute;
      right: 0;
    }
    .pic {
      // border-radius: 8px;
      // opacity: 1;
      // border: 1px dashed #d1e3fe;
      // width: 50px;
      width: 100%;
      height: 100%;
    }
  }
  .file-name {
    text-align: center;
  }
}
.img:hover {
  .el-icon-circle-close {
    display: inline;
    position: absolute;
    top: 2px;
    right: 0;
  }
}
</style>
